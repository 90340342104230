.display01,
.display02,
.quote01,
.leadLink,
.bodyLink,
.bodyTightLink,
.bodyTightSerif,
.smallLink,
.smallTightLink,
.tinyLink,
.tinyTightSerif {
	font-family: var(--font-primary);
	font-weight: 400;
}

.body,
.bodyTight,
.bodyTightBold,
.lead,
.leadBold,
.small,
.smallBold,
.smallTight,
.tiny,
.tinyBold,
.tinyTight,
.tinyTightLink,
.tinyTightBold {
	font-family: var(--font-secondary);
}

.title01,
.title02,
.title03,
.title04,
.title05,
.title06,
.subtitle01,
.subtitle01Link {
	font-family: var(--font-primary);
}

.title01,
.title02,
.title03,
.title04,
.title05,
.title06 {
	font-weight: var(--title-weight);
}

.subtitle01 {
	font-weight: var(--subtitle-font-weight);
}

.subtitle01Link {
	font-weight: var(--subtitleLink-font-weight);
}

.title01Bold,
.title02Bold,
.subtitle01Bold {
	font-family: var(--font-primary);
	font-weight: 600;
}

.lead,
.smallTight,
.tinyTight {
	font-weight: 400;
}

.small {
	font-family: var(--small-font-weight);
}

.body {
	font-weight: var(--body-font-weight);
}

.bodyBold,
.bodyTightBold,
.leadBold,
.smallBold,
.tinyBold,
.tinyTightLink {
	font-weight: 600;
}

.bodyTight {
	font-weight: var(--bodyTight-font-weight);
}

/*---display default---*/
.display01 {
	font-size: 34px;
	line-height: 1.2;
}

.display02 {
	font-size: 32px;
	line-height: 1.2;
}

/*---title default---*/
.title01 {
	font-size: 28px;
	line-height: 1.2;
}

.title01Bold {
	font-size: 28px;
	line-height: 1.2;
}

.title02 {
	font-size: 26px;
	line-height: 1.2;
}

.title02Bold {
	font-size: 26px;
	line-height: 1.2;
}

.title03 {
	font-size: 24px;
	line-height: 1.2;
}

.title04 {
	font-size: 23px;
	line-height: 1.2;
}

.title05 {
	font-size: 18px;
	line-height: 1.2;
}

.title06 {
	font-size: 18px;
	line-height: 1.2;
}

/*---subtitle default---*/
.subtitle01 {
	font-size: 15px;
	line-height: 1.2;
}

.subtitle01Bold {
	font-size: 15px;
	line-height: 1.2;
}

.subtitle01Link {
	font-size: 15px;
	line-height: 1.2;
}

.subtitleS,
.subtitleSBold {
	font-size: 16px;
	line-height: 1.5;
}

.quote01 {
	font-size: 15px;
	line-height: 1.2;
}

.lead {
	font-size: 18px;
	line-height: 1.2;
}

.leadBold {
	font-size: 18px;
	line-height: 1.2;
}

.leadLink {
	font-size: 18px;
	line-height: 1.2;
}

/*---body default---*/
.body,
.bodyBold,
.bodyLink,
.bodyTight,
.bodyTightBold,
.bodyTightLink,
.bodyTightSerif {
	font-size: 14px;
	line-height: 1.2;
}

.bodyCard {
	font-size: 15px;
	line-height: 1.2;
}

.bodyHeader {
	font-size: 14px;
	line-height: 1.2;
}

/*---small default---*/

.small {
	font-size: 13px;
	line-height: 1.5;
}

.smallBold {
	font-size: 13px;
	line-height: 1.5;
}

.smallLink {
	font-size: 13px;
	line-height: 1.5;
}

.smallTight {
	font-size: 13px;
	line-height: 1.2;
}

.smallTightBold {
	font-size: 13px;
	line-height: 1.2;
}

.smallTightLink {
	font-size: 13px;
	line-height: 1.2;
}

.tiny {
	font-size: 12px;
	line-height: 1.5;
}

.tinyBold {
	font-size: 12px;
	line-height: 1.5;
}

.tinyLink {
	font-size: 12px;
	line-height: 1.5;
}

.tinyTight,
.tinyTightSerif {
	font-size: 12px;
	line-height: 1.2;
}

.tinyTightBold {
	font-size: 12px;
	line-height: 1.2;
}

.tinyTightLink {
	font-size: 12px;
	line-height: 1.2;
}

.textLight {
	font-weight: 200 !important;
}

/*#TABLET*/
@media (--tablet) {
	/*---display default---*/
	.display01 {
		font-size: 56px;
	}

	.display02 {
		font-size: 48px;
	}

	/*---title default---*/
	.title01 {
		font-size: 40px;
	}

	.title01Bold {
		font-size: 40px;
	}

	.title02 {
		font-size: 36px;
	}

	.title02Bold {
		font-size: 36px;
	}

	.title03 {
		font-size: 32px;
	}

	.title04 {
		font-size: 26px;
	}

	.title05 {
		font-size: 22px;
	}

	.title06 {
		font-size: 20px;
	}

	/*---subtitle default---*/
	.subtitle01 {
		font-size: 17px;
	}

	.subtitle01Bold {
		font-size: 17px;
	}

	.subtitle01Link {
		font-size: 17px;
	}

	.subtitleS, .subtitleSBold {
		font-size: 16px;
		line-height: 1.5;
	}

	.quote01 {
		font-size: 17px;
	}
}

/*// LAPTOP*/
@media (--desktop) {
	/*---display default---*/
	.display01 {
		font-size: 72px;
	}

	.display02 {
		font-size: 60px;
	}

	/*---title default---*/
	.title01 {
		font-size: 52px;
	}

	.title01Bold {
		font-size: 52px;
	}

	.title02 {
		font-size: 48px;
	}

	.title02Bold {
		font-size: 48px;
	}

	.title03 {
		font-size: 40px;
	}

	.title04 {
		font-size: 32px;
	}

	.title05 {
		font-size: 24px;
	}

	.title06 {
		font-size: 20px;
	}

	/*---subtitle default---*/
	.subtitle01 {
		font-size: 18px;
	}

	.subtitle01Bold {
		font-size: 18px;
	}

	.subtitle01Link {
		font-size: 18px;
	}

	.subtitleS, .subtitleSBold {
		font-size: 16px;
		line-height:1.5;
	}

	.quote01 {
		font-size: 18px;
	}

	/*---body default---*/
	.body,
	.bodyBold,
	.bodyLink,
	.bodyTight,
	.bodyTightBold,
	.bodyTightLink,
	.bodyTightSerif {
		font-size: var(--body-size-desktop);
	}

	.bodyCard {
		font-size: 16px;
	}
}

/* BIG-SCREEN*/
@media (--big-screen) {
	/*---display default---*/
	.display01 {
		font-size: 88px;
	}

	.display02 {
		font-size: 76px;
	}

	/*---title default---*/
	.title01 {
		font-size: 60px;
	}

	.title01Bold {
		font-size: 60px;
	}

	.title02 {
		font-size: 56px;
	}

	.title02Bold {
		font-size: 56px;
	}

	.title03 {
		font-size: 48px;
	}

	.title04 {
		font-size: 40px;
	}

	.title05 {
		font-size: 32px;
	}

	.title06 {
		font-size: 22px;
	}

	/*---subtitle default---*/
	.subtitle01 {
		font-size: 22px;
	}

	.subtitle01Bold {
		font-size: 22px;
	}

	.subtitle01Link {
		font-size: 22px;
	}

	.subtitleS, .subtitleSBold {
		font-size: 18px;
		line-height: 1.5;
	}

	.quote01 {
		font-size: 22px;
	}

	.lead {
		font-size: 22px;
	}

	.leadBold {
		font-size: 22px;
	}

	.leadLink {
		font-size: 22px;
	}

	/*---body default---*/
	.body,
	.bodyBold,
	.bodyLink,
	.bodyTight,
	.bodyTightBold,
	.bodyTightLink,
	.bodyTightSerif {
		font-size: var(--body-size-big-screen);
	}

	.bodyCard {
		font-size: 16px;
	}

	.bodyHeader {
		font-size: 15px;
	}

	/*---small default---*/
	.small {
		font-size: 14px;
	}

	.smallBold {
		font-size: 14px;
	}

	.smallLink {
		font-size: 14px;
	}

	.smallTight {
		font-size: 14px;
	}

	.smallTightBold {
		font-size: 14px;
	}

	.smallTightLink {
		font-size: 14px;
	}

	.tiny {
		font-size: 13px;
	}

	.tinyBold {
		font-size: 13px;
	}

	.tinyLink {
		font-size: 13px;
	}

	.tinyTight,
	.tinyTightSerif {
		font-size: 13px;
	}

	.tinyTightBold {
		font-size: 13px;
	}

	.tinyTightLink {
		font-size: 13px;
	}
}
