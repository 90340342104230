@font-face {
	font-family: "Adjusted Trebuchet MS Fallback";
	src: local(Trebuchet MS);
	size-adjust: 108%;
	ascent-override: 90%;
	descent-override: 21%;
	line-gap-override: 24%;
}

@font-face {
	font-family: Poppins;
	src: url("../../static/fonts/Poppins/Poppins-Thin.woff2") format("woff2");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Poppins;
	src: url("../../static/fonts/Poppins/Poppins-ExtraLight.woff2") format("woff2");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Poppins;
	src: url("../../static/fonts/Poppins/Poppins-Light.woff2") format("woff2");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Poppins;
	src: url("../../static/fonts/Poppins/Poppins-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Poppins;
	src: url("../../static/fonts/Poppins/Poppins-Medium.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Poppins;
	src: url("../../static/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
